// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xLUz07tGl"];

const variantClassNames = {xLUz07tGl: "framer-v-t74ymc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xLUz07tGl", link: PN9BXTnVm, title: xGG1FwMcS = "Join Now for $149.99/Year", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xLUz07tGl", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1POhm", classNames)} style={{display: "contents"}}>
<Link href={PN9BXTnVm} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-t74ymc", className)} framer-ir0zxx`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xLUz07tGl"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(22, 22, 22, 0.15)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(180deg, rgba(14, 140, 80, 1) 0%, rgba(5, 114, 62, 1) 100%)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "22px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Join Now for $149.99/Year</motion.p></React.Fragment>} className={"framer-8ef3jt"} data-framer-name={"Join Now for $99/Year"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"kNg1Zv1QV"} style={{"--extracted-r6o4lv": " rgb(252, 253, 252)", "--framer-paragraph-spacing": "0px"}} text={xGG1FwMcS} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1POhm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1POhm .framer-ir0zxx { display: block; }", ".framer-1POhm .framer-t74ymc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 20px 32px 20px 32px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-1POhm .framer-8ef3jt { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1POhm .framer-t74ymc { gap: 0px; } .framer-1POhm .framer-t74ymc > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-1POhm .framer-t74ymc > :first-child { margin-left: 0px; } .framer-1POhm .framer-t74ymc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 68
 * @framerIntrinsicWidth 352
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"PN9BXTnVm":"link","xGG1FwMcS":"title"}
 */
const Framers4rSjRVyd: React.ComponentType<Props> = withCSS(Component, css, "framer-1POhm") as typeof Component;
export default Framers4rSjRVyd;

Framers4rSjRVyd.displayName = "Button Yearly or Monthly";

Framers4rSjRVyd.defaultProps = {height: 68, width: 352};

addPropertyControls(Framers4rSjRVyd, {PN9BXTnVm: {title: "Link", type: ControlType.Link}, xGG1FwMcS: {defaultValue: "Join Now for $149.99/Year", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framers4rSjRVyd, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/s4rSjRVyd:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])